<template>
  <div class="page-recompense">
    <div class="container">
      <fil-ariane :navigation="navigation"></fil-ariane>
      <h1>{{ $t("rewards.title") }} - <span v-html="getRaceLabel()"></span></h1>

      <router-link :to="{ name: 'race', params: {id: this.race_id} }" class="link-to-race">
        <font-awesome-icon icon="dog" /> {{ $t("rewards.back_to_race") }}
      </router-link>

      <div class="recompenses">
        <select-recompense v-for="(data, type) in getDogsByRewards" :title="data.title"
          :bus="bus" :candidates="data.candidates" :current="data.current"
          :reward_id="data.id" :class="type" :sex="data.sex"
          :dogs="data.dogs" :key="type" :value="data.current ? data.current.nr_cat : ''"
          :subtitle="getGroupedRewardLabel(data.id)"
          v-on:reward-changed="onChangeReward" />
      </div>
      <div class="actions">
        <button class="action cancel" @click="onReset">{{ $t('global.reset') }}</button>
        <button class="action debug" v-if="showDebug"  @click="onDebug">Debug</button>
        <button class="action close" @click="onSubmit">{{ $t('global.close') }}</button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';

import SelectRecompense from '@/components/SelectRecompense.vue';
import FilAriane from '@/components/FilAriane.vue';
import { EVALUATE_DOG } from '@/store/const/actions.type';

import dogMixin from '../common/dog.mixin';

const SEX_MALE = '1';
const SEX_FEMALE = '2';

/**
 * Grands principes :
 * - Chaque récompense potentielle est détaillée dans RèglesRécompenses.docx
 * - Les chiens peuvent être récompensés s'ils ont les pré-requis. Ex. "Meilleur Puppy" =>
 *    - Le chien doit être inscrit en "Puppy" (Classe "PUPPY")
 *    - avec un qualif qui comprend le flag mp=1 (VERY PROMISING)
 * - Un chien RCACS par ex. comprend tous les classements "1er" avec flag cacs = '1' (= Excellent)
 * - getCandidate renvoie les chiens potentiels
 * - getRCACS et getRCACIB renvoie spécifiquement les 2e si premier a CACS
 */

export default {
  components: { SelectRecompense, FilAriane },
  mixins: [dogMixin],
  name: 'rewards',
  data() {
    return {
      bus: new Vue(),
      showDebug: false,
    };
  },
  computed: {
    ...mapGetters([
      'getDogsByRaceId', 'getClassById', 'getQualifierById', 'currentLang', 'getRaceById',
      'getDogById', 'getDogResult', 'getDogsWithReward', 'hasDogReward', 'currentUser',
    ]),
    getDogsByRewards() {
      const allDogs = this.getDogsByRaceId(this.race_id);
      allDogs.forEach((d) => {
        const qualif = this.getQualifierById(d.classe_id, d.qualificatif_id);
        if (!qualif) {
          return;
        }
        if (this.currentLang === 'fr') {
          // eslint-disable-next-line no-param-reassign
          d.qualificatif_label = qualif.lib_fr;
        } else {
          // eslint-disable-next-line no-param-reassign
          d.qualificatif_label = qualif.lib_en;
        }
        // eslint-disable-next-line no-param-reassign
      });

      const res = {
        mj: { // *** Meilleur Jeune
          title: this.$i18n.t('rewards.mj'),
          id: 'mj',
          dogs: this.getCandidates(allDogs, { type: 'mj', position: 1 }),
          current: this.getSpecificDogWithReward(allDogs, 'mj'),
          candidates: [],
        },
        mv: { // *** Meilleur Vétéran
          title: this.$i18n.t('rewards.mv'),
          id: 'mv',
          dogs: this.getCandidates(allDogs, { type: 'mv', position: 1 }),
          current: this.getSpecificDogWithReward(allDogs, 'mv'),
          candidates: [],
        },
        mp: {
          title: this.$i18n.t('rewards.mp'),
          id: 'mp',
          dogs: this.getCandidates(allDogs, { type: 'mp' }),
          current: this.getSpecificDogWithReward(allDogs, 'mp'),
          candidates: [],
        },
        mb: {
          title: this.$i18n.t('rewards.mb'),
          id: 'mb',
          dogs: this.getCandidates(allDogs, { type: 'mb' }),
          current: this.getSpecificDogWithReward(allDogs, 'mb'),
          candidates: [],
        },
        cacvm: {
          title: this.$i18n.t('rewards.cacvm'),
          id: 'cacv',
          dogs: this.getCandidates(allDogs, {
            type: 'cacv', sex: SEX_MALE, position: 1,
          }),
          sex: 'M',
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'cacv', SEX_MALE),
          candidates: this.getArrayFrom([
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacv', SEX_MALE),
          ]),
        },
        cacvf: {
          title: this.$i18n.t('rewards.cacvf'),
          id: 'cacv',
          dogs: this.getCandidates(allDogs, {
            type: 'cacv', sex: SEX_FEMALE, position: 1,
          }),
          sex: 'F',
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'cacv', SEX_FEMALE),
          candidates: this.getArrayFrom([
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacv', SEX_FEMALE),
          ]),
        },
        cacjm: {
          title: this.$i18n.t('rewards.cacjm'),
          id: 'cacj',
          dogs: this.getCandidates(allDogs, {
            type: 'cacj', sex: SEX_MALE, position: 1,
          }),
          sex: 'M',
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'cacj', SEX_MALE),
          candidates: this.getArrayFrom([
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacj', SEX_MALE),
          ]),
        },
        cacjf: {
          title: this.$i18n.t('rewards.cacjf'),
          id: 'cacj',
          dogs: this.getCandidates(allDogs, {
            type: 'cacj', sex: SEX_FEMALE, position: 1,
          }),
          sex: 'F',
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'cacj', SEX_FEMALE),
          candidates: this.getArrayFrom([
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacj', SEX_FEMALE),
          ]),
        },
        cacsm: {
          title: this.$i18n.t('rewards.cacsm'),
          id: 'cacs',
          dogs: this.getCandidates(allDogs, {
            type: 'cacs', sex: SEX_MALE, exclusion: 'rcacs', position: 1,
          }),
          sex: 'M',
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'cacs', SEX_MALE),
          candidates: this.getArrayFrom([
            this.getSpecificDogWithRewardAndSex(allDogs, 'rcacs', SEX_MALE),
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacs', SEX_MALE),
          ]),
        },
        cacsf: {
          title: this.$i18n.t('rewards.cacsf'),
          id: 'cacs',
          dogs: this.getCandidates(allDogs, {
            type: 'cacs', sex: SEX_FEMALE, exclusion: 'rcacs', position: 1,
          }),
          sex: 'F',
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'cacs', SEX_FEMALE),
          candidates: this.getArrayFrom([
            this.getSpecificDogWithRewardAndSex(allDogs, 'rcacs', SEX_FEMALE),
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacs', SEX_FEMALE),
          ]),
        },
        rcacsm: {
          title: this.$i18n.t('rewards.rcacsm'),
          id: 'rcacs',
          dogs: this.getRCACS(allDogs, SEX_MALE),
          sex: 'M',
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'rcacs', SEX_MALE),
          candidates: this.getArrayFrom(
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacs', SEX_MALE),
          ),
        },
        rcacsf: {
          title: this.$i18n.t('rewards.rcacsf'),
          id: 'rcacs',
          sex: 'F',
          dogs: this.getRCACS(allDogs, SEX_FEMALE),
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'rcacs', SEX_FEMALE),
          candidates: this.getArrayFrom([
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacs', SEX_FEMALE),
          ]),
        },
        cacibm: {
          title: this.$i18n.t('rewards.cacibm'),
          id: 'cacib',
          dogs: this.getCACIB(allDogs, SEX_MALE),
          sex: 'M',
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'cacib', SEX_MALE),
          candidates: this.getArrayFrom([
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacib', SEX_MALE),
            this.getCACIB(allDogs, SEX_MALE),
          ]),
        },
        cacibf: {
          title: this.$i18n.t('rewards.cacibf'),
          id: 'cacib',
          dogs: this.getCACIB(allDogs, SEX_FEMALE),
          sex: 'F',
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'cacib', SEX_FEMALE),
          candidates: this.getArrayFrom([
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacib', SEX_FEMALE),
            this.getCACIB(allDogs, SEX_FEMALE),
          ]),
        },
        cacibjm: {
          title: this.$i18n.t('rewards.cacibjm'),
          id: 'cacibj',
          dogs: this.getCACIBJ(allDogs, SEX_MALE),
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'cacibj', SEX_MALE),
          candidates: this.getArrayFrom([
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacibj', SEX_MALE),
            this.getCACIBJ(allDogs, SEX_MALE),
          ]),
        },
        cacibjf: {
          title: this.$i18n.t('rewards.cacibjf'),
          id: 'cacibj',
          dogs: this.getCACIBJ(allDogs, SEX_FEMALE),
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'cacibj', SEX_FEMALE),
          candidates: this.getArrayFrom([
            this.getSpecificDogWithReward(allDogs, 'cacibj', SEX_FEMALE),
            this.getCACIBJ(allDogs, SEX_FEMALE),
          ]),
        },
        cacibvm: {
          title: this.$i18n.t('rewards.cacibvm'),
          id: 'cacibv',
          dogs: this.getCACIBV(allDogs, SEX_MALE),
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'cacibv', SEX_MALE),
          candidates: this.getArrayFrom([
            this.getSpecificDogWithReward(allDogs, 'cacibv', SEX_MALE),
            this.getCACIBV(allDogs, SEX_MALE),
          ]),
        },
        cacibvf: {
          title: this.$i18n.t('rewards.cacibvf'),
          id: 'cacibv',
          dogs: this.getCACIBV(allDogs, SEX_FEMALE),
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'cacibv', SEX_FEMALE),
          candidates: this.getArrayFrom([
            this.getSpecificDogWithReward(allDogs, 'cacibv', SEX_FEMALE),
            this.getCACIBV(allDogs, SEX_FEMALE),
          ]),
        },
        rcacibm: {
          title: this.$i18n.t('rewards.rcacibm'),
          id: 'rcacib',
          dogs: this.getRCACIB(allDogs, SEX_MALE),
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'rcacib', SEX_MALE),
          sex: 'M',
          candidates: this.getArrayFrom([
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacib', SEX_MALE),
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacs', SEX_MALE),
          ]).filter((dog) => this.canBeCACIB(dog)),
        },
        rcacibf: {
          title: this.$i18n.t('rewards.rcacibf'),
          id: 'rcacib',
          dogs: this.getRCACIB(allDogs, SEX_FEMALE),
          current: this.getSpecificDogWithRewardAndSex(allDogs, 'rcacib', SEX_FEMALE),
          sex: 'F',
          candidates: this.getArrayFrom([
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacib', SEX_FEMALE),
            this.getSpecificDogWithRewardAndSex(allDogs, 'cacs', SEX_FEMALE),
          ]).filter((dog) => this.canBeCACIB(dog)),
        },
        mbob: { // *** Meilleur de race
          // Les chiens ayant obtenu le CACIB ou CACS s’il n’y a pas de CACIB,
          // et 1er classe jeune, 1er classe vétéran
          title: this.$i18n.t('rewards.mbob'),
          id: 'mbob',
          dogs: this.getMBoB(allDogs),
          current: this.getSpecificDogWithReward(allDogs, 'mbob'),
          sex: undefined,
          candidates: this.getArrayFrom(
            this.getSpecificDogWithReward(allDogs, 'mbos'),
          ),
        },
        mbos: { // *** Meilleur de sexe opposé
          // TODO CACIB (ou CACS), 1er classe jeune, 1er classe vétéran de sexe différent du BOB
          title: this.$i18n.t('rewards.mbos'),
          id: 'mbos',
          dogs: this.getMBoS(allDogs),
          current: this.getSpecificDogWithReward(allDogs, 'mbos'),
          sex: undefined,
          candidates: this.getArrayFrom([
            this.getSpecificDogWithReward(allDogs, 'mbob'),
            this.getCandidates(allDogs, { type: 'mbos' }),
          ]),
        },
      };

      return res;
    },
    getDogs() {
      return this.getDogsByRaceId(this.race_id);
    },
    getClazz() {
      return this.getDogs.map((d) => this.getClassById(d.classe_id));
    },
    navigation() {
      return [
        { name: this.$i18n.t('breadcrumb.rewards', { name: this.currentUser.nom }), link: '/rewards' },
        { name: this.getRaceLabel(this.getRaceById(this.race_id)), link: '' },
      ];
    },
  },
  props: {
    race_id: { type: String },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    getRaceLabel() {
      const race = this.getRaceById(this.race_id);
      if (this.currentLang === 'fr') {
        return race.fr;
      }
      return race.en;
    },
    getGroupedRewardLabel(reward) {
      const race = this.getRaceById(this.race_id);
      if (reward === 'cacib' && race.regroupement_cacib) {
        return `Regroupement : ${race.regroupement_cacib}`;
      }
      if (reward === 'cacs' && race.regroupement_cacs) {
        return `Regroupement : ${race.regroupement_cacs}`;
      }
      return '';
    },
    getRandom(arr) {
      const nb = Math.floor(Math.random() * 20);
      const nbInArr = arr.length;
      const res = [];
      for (let i = 0; i < nb; i += 1) {
        const newDog = arr[Math.floor(Math.random() * nbInArr)];
        // console.log('aaaaa', newDog, res);
        if (!res.find((d) => d.nr_cat === newDog.nr_cat)) {
          res.push(newDog);
        }
      }
      return res;
    },
    getSpecificDogWithReward(arr, reward) {
      const res = this.getSpecificDogWithRewardAndSex(arr, reward, null);
      return res;
    },

    getArrayFrom(obj) {
      const arrayObj = Array.isArray(obj) ? obj.flat(2) : [obj];
      const res = arrayObj.filter((v) => v !== null && v !== undefined);
      return res; // [...new Set(res)]; // removing duplicates
    },
    getRCACS(arr, sex) {
      let dogsForRCACS = this.getCandidates(arr, {
        type: 'cacs', exclusion: 'rcacs', position: 1, sex,
      });

      const existingCACS = this.getSpecificDogWithReward(arr, 'cacs');
      if (existingCACS && Object.entries(existingCACS).length !== 0) {
        const nrTwo = this.getCandidates(arr, {
          type: 'cacs', sex, qualification: existingCACS.qualification_id,
        }).filter((d) => d.classement === '2');
        if (nrTwo) {
          dogsForRCACS = dogsForRCACS.concat(nrTwo);
        }
        dogsForRCACS = dogsForRCACS.filter((d) => d.nr_cat !== existingCACS.nr_cat);
      }
      return dogsForRCACS;
    },
    getCACIB(arr, sex) {
      const candidates = this.getCandidates(arr, {
        type: 'cacib', sex, exclusion: 'rcacib', position: 1, // inclusion: 'cacs',
      });
      const existingCACS = this.getSpecificDogWithRewardAndSex(arr, 'cacs', sex);
      if (existingCACS) {
        if (this.canBeCACIB(existingCACS)) {
          candidates.push(existingCACS);
        }
      }
      return candidates;
    },
    getCACIBJ(arr, sex) {
      return this.getCandidates(arr, {
        type: 'cacibj', position: 1, sex,
      });
    },

    getCACIBV(arr, sex) {
      return this.getCandidates(arr, {
        type: 'cacibv', position: 1, sex,
      });
    },
    canBeCACIB(dog) {
      const q = this.getQualifierById(dog.qualificatif_id);
      return q && q.cacib !== '0';
    },
    getRCACIB(arr, sex) {
      let dogsForRCACIB = this.getCandidates(arr, {
        type: 'cacib', exclusion: 'rcacib', position: 1, sex,
      });
      const existingCACS = this.getSpecificDogWithReward(arr, 'cacs');
      if (existingCACS && Object.entries(existingCACS).length !== 0) {
        const nrTwo = this.getCandidates(arr, {
          type: 'cacs', sex, qualification: existingCACS.qualification_id,
        }).filter((d) => d.classement === '2');
        if (nrTwo && this.canBeCACIB(nrTwo)) {
          dogsForRCACIB = dogsForRCACIB.concat(nrTwo);
        }
        dogsForRCACIB = dogsForRCACIB.filter((d) => d.nr_cat !== existingCACS.nr_cat);
      }

      // console.log('getRCACIB after nr2', dogsForRCACIB);
      return dogsForRCACIB;
    },
    getMBoB(arr) {
      // todo manque 1ere place
      const dogsForMBoB = this.getCandidates(arr, {
        type: 'mbob', inclusion: ['cacib', 'cacs'], position: 1,
      });
      return dogsForMBoB;
    },
    getMBoS(arr) {
      const res = this.getCandidates(arr, { type: 'mbos', exclusion: 'mbob' });
      const current = this.getSpecificDogWithReward(arr, 'mbob');
      if (current) {
        return res.filter((d) => d.nr_sexe !== current.nr_sexe);
      }
      return [];
    },
    getCandidates(arr, filters) {
      const candidates = arr.filter((d) => {
        const cls = this.getClassById(d.classe_id);

        if (filters.sex && filters.sex !== d.nr_sexe) {
          return false;
        }

        const qualif = cls.qualificatifs.find((q) => q.id === d.qualificatif_id);
        if (!qualif) {
          return false;
        }
        if (qualif[filters.type] === '0') {
          return false;
        }

        if (filters.inclusion && this.hasDogReward(d.nr_cat, filters.inclusion)) {
          // console.log('Validating inclusion ! : ', d.nr_cat, ' : ', filters.inclusion);
          return true;
        }
        if (filters.exclusion && this.hasDogReward(d.nr_cat, filters.exclusion)) {
          return false;
        }
        if (filters.position
        && (parseInt(d.classement, 10) === 0 || d.classement > filters.position)) {
          // console.log('testing position : ', d.nr_cat, ' : ', d.classement, filters.position);
          return false;
        }

        return true;
      });

      // console.table(JSON.parse(JSON.stringify(candidates)));
      return candidates;
    },
    getSpecificDogWithRewardAndSex(arr, reward, sex) {
      let res;
      if (sex) {
        res = arr.filter((d) => d.nr_sexe === sex).find((d) => d.recompenses.split(',').includes(reward));
      } else {
        res = arr.find((d) => d.recompenses.split(',').includes(reward));
      }
      return res || null;
    },
    onSubmit() {
      this.$router.push({ name: 'rewards' });
    },
    onDebug() {
      const allDogs = this.getDogsByRaceId(this.race_id);
      let displayed = allDogs.map((dog) => ({
        nr_cat: dog.nr_cat,
        qualificatif_label: dog.qualificatif_label,
        nr_sexe: dog.nr_sexe,
        classement: dog.classement,
        recompenses: dog.recompenses,
        commentaire: dog.commentaire,
      }));
      console.group('Debug - Chiens');
      console.table(displayed);
      console.groupEnd();

      const res = this.getDogsByRewards;
      displayed = Object.entries(res).map(([k, r]) => {
        const {
          title, id, dogs, current, candidates, sex,
        } = r;
        const dogsCat = JSON.stringify(dogs.map((d) => parseInt(d.nr_cat, 10)));
        const currentCat = current ? current.nr_cat : '-';
        const candidatesCat = JSON.stringify(candidates.map((d) => (d ? parseInt(d.nr_cat, 10) : '-')));
        return {
          k,
          title,
          id,
          dogsCat,
          currentCat,
          candidatesCat,
          sex,
        };
      });
      console.group('Debug - Recompenses');
      console.table(displayed);
      console.groupEnd();

      console.group('Specific (MBOS)');
      const a = this.getSpecificDogWithReward(allDogs, 'mbob');
      console.table(a);
      console.groupEnd();
    },
    onChangeReward(e) {
      const dogWithReward = this.getDogById(e.nr_cat);
      const dogRemoved = this.getDogById(e.old_pos);

      if (dogWithReward) {
        const existing = dogWithReward.recompenses ? dogWithReward.recompenses.split(',') : [];
        const allRewards = new Set(existing);

        const getExistingResults = this.getDogResult(e.nr_cat);

        allRewards.add(e.reward_id);
        dogWithReward.recompenses = Array.from(allRewards).join(',');
        this.$store.dispatch(EVALUATE_DOG, {
          dog: dogWithReward,
          comment: getExistingResults.comment,
          qualifier: getExistingResults.qualifier,
        }).then(() => {
          // console.log('After changing reward for dog : ', dogWithReward.nr_cat,
          //   dogWithReward.recompenses);
        });
      }

      if (dogRemoved) {
        const previousRewards = dogRemoved.recompenses
          ? dogRemoved.recompenses.split(',') : [];
        const remainingRewards = new Set(previousRewards);

        const getRemovedDogResults = this.getDogResult(e.old_pos);
        if (getRemovedDogResults) {
          remainingRewards.delete(e.reward_id);

          dogRemoved.recompenses = Array.from(remainingRewards).join(',');

          this.$store.dispatch(EVALUATE_DOG, {
            dog: dogRemoved,
            comment: getRemovedDogResults.comment,
            qualifier: getRemovedDogResults.qualifier,
          }).then(() => {
          });
        }
      }
    },
    onReset() {
      console.log('reset', this.getDogsWithReward(this.race_id));
      this.getDogsWithReward(this.race_id).forEach((d) => {
        const existingResults = this.getDogResult(d.nr_cat);
        console.log(existingResults);
        // eslint-disable-next-line no-param-reassign
        d.recompenses = '';

        this.$store.dispatch(EVALUATE_DOG, {
          dog: d,
          comment: existingResults.comment,
          qualifier: existingResults.qualifier,
        }).then(() => {
          console.log('Reset : removing rewards for dog : ', d.nr_cat);
          this.bus.$emit('rewards.reset', {});
        });
      });
    },
  },
};
</script>

<style lang="scss">
  .page-recompense {
    background: white; border: 1px gray solid;
    .recompenses {
      display: grid; grid-template-columns: 1fr 1fr; grid-gap: 2rem;
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
      .select-recompense {
        .selected { border:1px black solid; }
        :not(.selected) .item:hover { background-color: #EEE}

        h3 { background-color:white; background-position: 0.2rem center;
          background-size: 1.5rem; background-repeat: no-repeat;
        }
        &.rcacsf, &.cacs, &.rcacsm, &.cacsm, &.cacsf
        { h3 { background-image: url('../assets/CACS.png'); padding-left: 2rem } }
        &.cacjf, &.cacjm
        { h3 { background-image: url('../assets/CACS-J.png'); padding-left: 2rem } }
        &.cacvf, &.cacvm
        { h3 { background-image: url('../assets/CACS-V.png'); padding-left: 2rem } }
        &.rcacsf, &.cacs, &.rcacsm, &.cacsm, &.cacsf
        { h3 { background-image: url('../assets/CACS.png'); padding-left: 2rem } }
        &.cacibjm {
          order: 19;
          h3 { background-image: url('../assets/cacibj.png'); padding-left: 2rem }
        }
        &.cacibjf {
          order: 20;
          h3 { background-image: url('../assets/cacibj.png'); padding-left: 2rem }
        }
        &.cacibvm {
          order: 21;
          h3 { background-image: url('../assets/cacibv.png'); padding-left: 2rem }
        }
        &.cacibvf {
          order: 22;
          h3 { background-image: url('../assets/cacibv.png'); padding-left: 2rem }
        }
        &.rcacibf, &.cacib, &.rcacibm, &.cacibm, &.cacibf
        { h3 { background-image: url('../assets/CACIB.png'); padding-left: 2rem } }
        &.cacsm {
          order:11;
          .words [--nth-word="2"] { background-color: #61A0D7; color: white }
          .item {background: #FFF;}
          .item.selected {background: #61A0D7;color: white}
        }
        &.rcacsm {
          order:12;
          //h3 { background-color: #61A0D7; color: white }
          .item {background: #FFF;}
          .item.selected {background: #61A0D7; color: white }
        }
        &.cacsf {
          order:13;
          .item {background: #FFF;}
          .item.selected {background: #FA9DCC; color: white }
        }
        &.rcacsf {
          order:14;
          //h3 { background-color: #bfc7cd; color: white }
          .item {background: #FFF;}
          .item.selected {background: #bfc7cd; color: white}
        }
        &.cacjm {
          order:15;
          .words [--nth-word="2"] { background-color: #61A0D7; color: white }
          .item {background: #FFF;}
          .item.selected {background: #61A0D7;color: white}
        }
        &.cacjf {
          order:16;
          .words [--nth-word="2"] { background-color: #61A0D7; color: white }
          .item {background: #FFF;}
          .item.selected {background: #61A0D7;color: white}
        }

        &.cacib, &.cacibf, &.cacibm {
          h3 { background-color: #C4EbC4;}
        .item {background: #FFF;}
          .item.selected {background: #C4EbC4;  }
        }
        &.cacibm {order:15;}
        &.rcacibm {order:16;}
        &.cacibf {order:17;}
        &.rcacibf {order:18;}
        &.rcacib {
          //h3 { background-color: #FFDB6C; color: #555 }
          .item {background: #FFF;}
          .item.selected {background: #FFDB6C; color: #555  }
        }
        &.mb {
          h3 { background-color: #FFC400; color: white }
          .item {background: #FFF;}
          .item.selected {background: #f5d367;}
        }
        &.mp {
          h3 { background-color: #f2dee1; }
          .item.selected {background: #f2dee1;}
        }
        &.mbob {
          h3 { background-color: #78bb59; color: #FFF }
          .item.selected {background: #78bb59; color:#FFF }
        }
        &.mbos {
          h3 { background-color: #78bb59; color: #FFF }
          .item.selected {background: #78bb59; color: #FFF;}
        }
        &.mj {
          order:8;
          h3 { background-color: #edeab5; color: #000 }
          .item.selected {background: #edeab5; }
        }
        &.mv {
          order:9;
          h3 { background-color: #c4e6c8;  }
          .item.selected {background: #c4e6c8; }
        }
        &.mb {
          h3 { background-color: #f1ce68; }
          .item.selected {background: #f1ce68;}
        }
      }

    }
    .link-to-race {font-size: 1.5rem}
    .actions {
      margin:3rem 0; display: flex; justify-content: space-between;
      .cancel {margin-right:2rem;}
      .debug {background: black; color: white }
    }
  }
</style>
