<template>
   <div class="select-recompense" v-if="showArea()">
     <h3 v-if="sex" class="words">
       {{ title.split(' ')[0] }}
       <span class="sex" v-bind:class="sex" v-html="title.split(' ').slice(1).join(' ')"></span>
     </h3>
    <h3 v-if="sex === undefined" class="words" v-html="title"></h3>
    <h4 class="subtitle" v-if="subtitle" >
      <font-awesome-icon icon="exclamation-triangle"/>&nbsp;&nbsp;
      <span v-html="subtitle"></span>
    </h4>
    <h5 class="empty" v-if="allDogs.length === 0">{{ $t('rewards.none_available') }}</h5>
    <ul v-if="allDogs.length !== 0">
      <li v-for="dog of allDogs" :key="dog.id" @click="onClick($event, dog)"
        :class="'item ' + (selectedValue === dog.nr_cat ? 'selected' : '') "
      >
        <div :class="'dog ' + (dog.nr_sexe === '1' ? 'male' : 'female')"></div>
        <div class="title">{{dog.nr_cat}}</div>
        <div class="clazz" v-text="dog.lib_classe" />
        <div class="qualifiers" v-text="dog.qualificatif_label" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'select-recompense',
  props: {
    title: String,
    subtitle: String,
    reward_id: String,
    dogs: Array,
    candidates: Array,
    current: Object,
    value: String,
    sex: String,
    bus: Object,
  },
  mounted() {
    this.selectedValue = this.value;
    this.bus.$on('rewards.reset', () => {
      this.selectedValue = '';
    });
  },
  methods: {
    onClick(event, dog) {
      let newDogReward = dog.nr_cat;
      if (this.selectedValue === dog.nr_cat) { // unselecting
        newDogReward = '';
      }

      this.$emit('reward-changed', { nr_cat: newDogReward, old_pos: this.selectedValue, reward_id: this.reward_id });
      this.selectedValue = newDogReward;
    },
    showArea() {
      return this.dogs.length !== 0 || this.candidates.length !== 0 || this.value !== '';
    },
  },
  computed: {
    allDogs() {
      if (this.current) {
        return [...new Set(this.dogs.concat(this.current))];
      }
      return this.dogs;
    },
  },
  data() {
    return {
      selectedValue: null,
    };
  },
};
</script>

<style lang="scss">
.select-recompense {
  user-select: none;
  .title {font-weight: bold; font-size: 1rem; padding-bottom: .5rem;}
  h3 {
    text-align: center; font-size: 1.2rem; border: 1px gray solid;
    height: 2rem; line-height: 2rem; padding: .5rem 1rem;
    .sex.M {color: #3535d5    }
    .sex.F {color: #de4a63
    }
  }
  .subtitle {
    font-style: italic; text-align: center; font-size:1.2rem;
    svg {color:orange}
  }
  ul {
    display: grid; grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    li {
      padding: .5rem; border: 1px #CCC solid; text-align: center;
      &.selected {background: #CCC}
    }
    .dog {
      width: .5rem; height: .5rem; position: absolute; border:1px #AAA solid;
      &.male {background-color: lightblue}
      &.female { background-color: pink}

    }
    .qualifiers {text-decoration: underline}
  }
}
</style>
